import L from "leaflet";
import React, { Component } from "react";
import { connect } from "react-redux";
import { appStore } from "../store";
import { actionBuildMarker, actionSetAreas, actionSetStops } from "../actions/withRedux";
import { actionHandleLegendState, actionSetZoom, actionSetCenter } from "../actions/map";
import { getLine, assetsPath, handleKeyPress, compareValues, getURLSearchParams } from "../services/tools";
import history from "../history";
import { OnScreen } from "../hoc/onScreen";
import { componentIfModuleFallback } from "../utils/codeSplit";
import "leaflet/dist/leaflet.css";
import "react-leaflet-markercluster/dist/styles.min.css";

const Leaflet = componentIfModuleFallback("Leaflet", () => import("./Leaflet"));
const LeafletNetwork = componentIfModuleFallback("LeafletNetwork", () => import("./LeafletNetwork"));
const LeafletProjects = componentIfModuleFallback("LeafletProjects", () => import("./LeafletProjects"));
const LeafletTerritories = componentIfModuleFallback("LeafletTerritories", () => import("./LeafletTerritories"));
const { REACT_APP_START_POINT, REACT_APP_ZOOM, REACT_APP_TYPE } = process.env;

class Map extends Component {
  componentDidMount() {
    const center = this.props.startOnMobile
      ? JSON.parse(REACT_APP_START_POINT).mobile
      : JSON.parse(REACT_APP_START_POINT).desktop;

    const zoom = this.props.startOnMobile ? JSON.parse(REACT_APP_ZOOM).mobile : JSON.parse(REACT_APP_ZOOM).desktop;

    appStore.dispatch(actionSetZoom(zoom));
    appStore.dispatch(actionSetCenter(center));
  }

  componentDidUpdate() {
    // Do not run this lifecycle on network map
    if (REACT_APP_TYPE === "network" || REACT_APP_TYPE === "projects") {
      return;
    }

    let { areas, stops } = this.props;
    const { lines, reactAreas } = this.props;

    areas = areas.filter((m) => {
      return m.lines.length;
    });

    stops = stops.filter((m) => {
      return m.lines.length;
    });

    if (!reactAreas && areas.length > 0) {
      const reactAreas = [];
      const reactStops = [];

      for (let i = 0; i < areas.length; i++) {
        const area = areas[i];
        const mode = retrieveMostImportantMode(lines, area);

        reactAreas.push(
          appStore.dispatch(
            actionBuildMarker(area, {
              icon: L.icon({
                className: "lc-stop-marker",
                iconUrl: assetsPath(`/assets/images/stops/${mode}.svg`),
              }),
              area,
              zIndexOffset: 50,
            })
          )
        );
      }

      for (let i = 0; i < stops.length; i++) {
        const stop = stops[i];
        const mode = retrieveMostImportantMode(lines, stop);

        reactStops.push(
          appStore.dispatch(
            actionBuildMarker(stop, {
              icon: L.icon({
                className: "lc-stop-marker" + (stop.terminus ? " lc-stop-marker-terminus" : ""),
                iconUrl: assetsPath(`/assets/images/stops/${mode}.svg`),
              }),
              stop,
              zIndexOffset: 50,
            })
          )
        );
      }

      // Dispatch markers in the store
      appStore.dispatch(actionSetAreas(reactAreas));
      appStore.dispatch(actionSetStops(reactStops));
    }
  }

  render() {
    const { showBoard, startOnMobile, isLegendOpen } = this.props;
    const avoidPaths = ["/route-calculation"];

    // Run a separate map for network map
    if (REACT_APP_TYPE === "network" && !avoidPaths.includes(history.location.pathname)) {
      const params = getURLSearchParams(history.location);

      return (
        <>
          {isLegendOpen && !params.line && !params.stop_point && !params.stop_area && !params.town && (
            <div
              className="lc-legend-panel"
              onClick={() => {
                appStore.dispatch(actionHandleLegendState(isLegendOpen));
              }}
              onKeyPress={(e) => handleKeyPress(e, () => appStore.dispatch(actionHandleLegendState(isLegendOpen)))}
              role="button"
              tabIndex="0"
            >
              <span className="lc-legend-close">╳</span>
              <div
                className="lc-legend-img"
                style={{
                  backgroundImage: `url(/assets/images/legend-img.svg)`,
                }}
                alt="legend"
              ></div>
            </div>
          )}
          <LeafletNetwork showBoard={showBoard} startOnMobile={startOnMobile} isLegendOpen={isLegendOpen} />
        </>
      );
    } else if (REACT_APP_TYPE === "projects") {
      return <LeafletProjects showBoard={showBoard} startOnMobile={startOnMobile} />;
    } else if (REACT_APP_TYPE === "territories") {
      return <LeafletTerritories showBoard={showBoard} startOnMobile={startOnMobile} />;
    } else {
      return (
        <>
          {isLegendOpen && (
            <div
              className="lc-legend-panel"
              onClick={() => {
                appStore.dispatch(actionHandleLegendState(isLegendOpen));
              }}
              onKeyPress={(e) => handleKeyPress(e, () => appStore.dispatch(actionHandleLegendState(isLegendOpen)))}
              role="button"
              tabIndex="0"
            >
              <span className="lc-legend-close">╳</span>
              <div
                className="lc-legend-img"
                style={{
                  backgroundImage: `url(/assets/images/legend-img.svg)`,
                }}
                alt="legend"
              ></div>
            </div>
          )}
          <OnScreen>{(onScreen) => <Leaflet onScreen={onScreen} {...this.props} />}</OnScreen>
        </>
      );
    }
  }
}

const retrieveMostImportantMode = (lines, stop) => {
  // TODO : find a way to sort lines by important mode (with modes.json from wynd ?)
  stop.lines = stop.lines.sort(compareValues("cat", "desc"));

  const info = getLine(lines, stop.lines[0]);
  let mode = "bus";

  if (info.mode === "commercial_mode:Ter") {
    mode = "ter";
  } else if (info.mode === "commercial_mode:Métro") {
    mode = "subway";
  } else if (info.mode === "commercial_mode:Funiculaire") {
    mode = "funicular";
  } else if (info.mode === "commercial_mode:Tramway") {
    mode = "tramway";
  } else if (info.mode === "commercial_mode:BusC") {
    mode = "c-lines";
  } else if (info.mode === "commercial_mode:Ferry") {
    mode = "ferry";
  } else if (info.mode === "commercial_mode:Autocar") {
    mode = "autocar";
  }

  return mode;
};

const mapStateToProps = (state) => {
  return {
    config: state.app.config,
    modules: state.app.modules,
    isMobile: state.app.isMobile,
    component: state.app.component,
    areas: state.app.areas,
    stops: state.app.stops,
    lines: state.app.lines,
    isLegendOpen: state.map.isLegendOpen,
    reactAreas: state.map.reactAreas,
    reactStops: state.map.reactStops,
    reduxMarkers: state.map.reduxMarkers,
    selectedLine: state.map.selectedLine,
    heavyLines: state.map.heavyLines,
    hideHeavyLines: state.map.hideHeavyLines,
    complementaryLine: state.map.complementaryLine,
    complementaryLines: state.map.complementaryLines,
    customLines: state.map.customLines,
    customMarkers: state.map.customMarkers,
    transportPlaces: state.map.transportPlaces,
    mapPlaces: state.map.mapPlaces,
    places: state.map.places,
    territoryOutline: state.map.territoryOutline,
    entranceMapMarkers: state.map.entranceMapMarkers,
    entrancePopup: state.map.entrancePopup,
    bikePaths: state.map.bikePaths,
    bikes: state.map.bikes,
    reactTourismPartnersStops: state.map.reactTourismPartnersStops,
    reactLines: state.map.reactLines,
    zoom: state.map.zoom,
    center: state.map.center,
    aroundPin: state.map.aroundPin,
    aroundCircles: state.map.aroundCircles,
    markerMode: state.map.markerMode,
    languageFile: state.app.languageFile,
    openedMarker: state.map.openedMarker,
    isExpandedMap: state.app.isExpandedMap,
  };
};

export default connect(mapStateToProps)(Map);
