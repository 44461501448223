import React, { useRef, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { lineImageHeight } from "../../scss/app.scss";
import { luminance } from "luminance-js";

const { REACT_APP_DISRUPTION } = process.env;

const ContentContainer = styled.div`
  position: relative;
  &.active #lc-line-overlay-content {
    opacity: 1;
  }
`;

const OverlayContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #${(props) => props.color};
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  height: ${(props) => (props.styleLine.includes("image") ? lineImageHeight : "100%")};
  width: 100%;
  opacity: 0;
  transition: ${(props) => `all ${props.transitionAnimTime}ms ease-in-out 0s`};
  color: ${(props) => (luminance(props.color) > 0.5 ? "#333" : "#fff")};
  font-size: 1.2em;
  font-weight: bold;
  border-radius: 2px;
`;

const UIDisruptedLineOverlay = (props) => {
  const { line, styleLine, startAnimFadeAt = 3000, transitionAnimTime = 1500, intervalAnim = 5500 } = props;
  const description = "!";
  const overlay = useRef();
  const disruptionsInLine = useSelector((state) => state.board.disruptionsInLine);
  const { stops, areas } = useSelector((state) => state.app);
  const [isDisruptedLine, setIsDisruptedLine] = useState(false);
  const hasOverlay = REACT_APP_DISRUPTION ? JSON.parse(REACT_APP_DISRUPTION).overlay : null;

  useEffect(() => {
    let interval = undefined;

    const animation = () => {
      setTimeout(() => {
        overlay.current && overlay.current.classList.add("active");
        setTimeout(() => {
          overlay.current && overlay.current.classList.remove("active");
        }, transitionAnimTime);
      }, startAnimFadeAt);
    };

    if (isDisruptedLine && !interval) {
      animation();

      interval = setInterval(() => {
        animation();
      }, intervalAnim);
    }

    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, [isDisruptedLine]);

  useEffect(() => {
    if (disruptionsInLine && disruptionsInLine.length > 0) {
      const filteredDisruptions = disruptionsInLine.filter((d) => d.severity === "blocking");

      for (let i = 0; i < filteredDisruptions.length; i++) {
        const disruption = filteredDisruptions[i];

        for (let j = 0; j < disruption.impacted_objects.length; j++) {
          const impacted = disruption.impacted_objects[j];

          switch (impacted.type) {
            case "line":
              if (impacted.line === line.id) {
                setIsDisruptedLine(true);
                continue;
              }

              break;
            case "stop_point":
              const stop = stops.find((s) => s.id === impacted.id);

              if (stop && stop.lines.map((l) => l.id).includes(line.id)) {
                setIsDisruptedLine(true);
                continue;
              }

              break;
            case "stop_area":
              const area = areas.find((a) => a.id === impacted.id);

              if (area && area.lines.map((l) => l.id).includes(line.id)) {
                setIsDisruptedLine(true);
                continue;
              }

              break;
            case "section":
              const from = impacted.from;
              const to = impacted.to;

              if (from.includes("stop_point")) {
                const stop = stops.find((s) => s.id === from);

                if (stop && stop.lines.map((l) => l.id).includes(line.id)) {
                  setIsDisruptedLine(true);
                  continue;
                }
              } else if (from.includes("stop_area")) {
                const area = areas.find((a) => a.id === from);

                if (area && area.lines.map((l) => l.id).includes(line.id)) {
                  setIsDisruptedLine(true);
                  continue;
                }
              } else {
              }

              if (to.includes("stop_point")) {
                const stop = stops.find((s) => s.id === to);

                if (stop && stop.lines.map((l) => l.id).includes(line.id)) {
                  setIsDisruptedLine(true);
                  continue;
                }
              } else if (to.includes("stop_area")) {
                const area = areas.find((a) => a.id === to);

                if (area && area.lines.map((l) => l.id).includes(line.id)) {
                  setIsDisruptedLine(true);
                  continue;
                }
              } else {
              }

              break;

            default:
              break;
          }
        }
      }
    }
    // eslint-disable-next-line
  }, [disruptionsInLine]);

  return (
    <ContentContainer ref={overlay}>
      {hasOverlay && isDisruptedLine && (
        <OverlayContainer
          color={line.color}
          styleLine={styleLine}
          transitionAnimTime={transitionAnimTime}
          id="lc-line-overlay-content"
        >
          {description}
        </OverlayContainer>
      )}
      {props.children}
    </ContentContainer>
  );
};

export default UIDisruptedLineOverlay;
