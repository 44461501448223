import React from "react";
import { translate, assetsPath } from "../services/tools";
import styled from "styled-components";

const RealTimeImg = styled.img`
  height: 8px;
  width: 8px;
  margin-right: 3px;
`;

export default class CustomPopupInterface {
  constructor(data, project) {
    switch (project) {
      case "bordeaux-tbm":
        const displayWaitingTime = (waittime) => {
          const wt = waittime.split(":");
          const hours = parseInt(wt[0]);
          const minutes = parseInt(wt[1]);
          const secondes = parseInt(wt[2]);

          if (hours === 0 && minutes === 0 && secondes >= 0) {
            return translate("vehicle-close", false);
          } else if (hours === 0 && minutes > 0) {
            return minutes + " min";
          } else {
            return hours + "h" + minutes;
          }
        };

        this.content = (
          <table className="lc-custom-popup-schedules">
            <thead>
              <tr>
                <th>Ligne</th>
                <th>Destination</th>
                <th>Prochains passages dans</th>
              </tr>
            </thead>
            <tbody>
              {data.schedules === undefined || (data.schedules && data.schedules.length === 0) ? (
                <tr>
                  <td className="lc-line-code">
                    <img
                      src={assetsPath("/assets/images/lines/" + data.line.code + ".svg")}
                      alt={data.line.code}
                      aria-hidden="true"
                    />
                  </td>
                  <td colSpan="2">{translate("no-schedules")}</td>
                </tr>
              ) : (
                data.schedules?.map((schedule, index) => (
                  <tr key={"schedule-" + index}>
                    <td className="lc-line-code">
                      <img
                        src={assetsPath("/assets/images/lines/" + data.line.code + ".svg")}
                        alt={data.line.code}
                        aria-hidden="true"
                      />
                    </td>
                    <td>{schedule.destination_name}</td>
                    <td>
                      {schedule.realtime === "1" && (
                        <RealTimeImg
                          src={assetsPath("/assets/images/realtime.gif")}
                          alt={translate("realtime-gif-alt")}
                          title={translate("realtime-gif-title")}
                        />
                      )}{" "}
                      {displayWaitingTime(schedule.waittime)}
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        );

        break;
      default:
        break;
    }
  }
}
