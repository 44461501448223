import axios from "../middlewares/axios";
import L from "leaflet";
import { luminance } from "luminance-js";
import React, { Component, Suspense } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import {
  actionScrollEnd,
  actionSetComponent,
  actionSetProjectsMenuID,
  actionSetExpandedMap,
  actionSetStreetviewPosition,
} from "../actions/app";
import { actionSetGeolocationError, actionSetLineInformation, actionSetMobilityObject } from "../actions/board";
import ModalGeneric from "./ModalGeneric";
import {
  actionSetReduxMarkers,
  actionSetCustomMarkers,
  actionSetAllLinesGeojson,
  actionSetAllLinesintersecGeojson,
  actionSetAllLinesSelected,
} from "../actions/map";
import {
  actionBuildHeavyLines,
  actionBuildComplementaryLines,
  actionBuildCustomLines,
  actionBuildMapPlaces,
  actionBuildMarker,
  actionOnLineSelected,
} from "../actions/withRedux";
import history from "../history";
import {
  addPolesExchanges,
  addResizeEvent,
  getURLSearchParams,
  assetsPath,
  handleKeyPress,
  translate,
  isActiveModule,
  envVarToBool,
} from "../services/tools";
import { appStore } from "../store";
import { updateDataLayer, UpdatePageView } from "../tracking";
import { removeMapEvents, updateMapEvents, zoomOnTerritoryOutline } from "../utils/leaflet/map";
import { substringCoords, updateURLState } from "../utils/leaflet/tools";
import { buildEntranceMap, buildCustomMarkers, buildAllLines, buildStreeviewMarker } from "../services/map";
import { message } from "../services/message";
import UITitleBoard from "./styled/UITitleBoard";
import { componentIfModule } from "../utils/codeSplit";

const {
  REACT_APP_START_POINT,
  REACT_APP_ZOOM,
  REACT_APP_HEAVY_LINES,
  REACT_APP_AREAS_ZOOM_LEVEL,
  REACT_APP_POLES,
  REACT_APP_TERRITORY_OUTLINE,
  REACT_APP_TYPE,
  REACT_APP_EXPANDED_MAP,
  REACT_APP_HEADER,
  REACT_APP_STREETVIEW,
  REACT_APP_HIDE_BOARD_NO_BACK_BUTTON,
  REACT_APP_INIT_MAP_ON_BACK,
} = process.env;

const TourismPartners = componentIfModule("TourismPartners", () => import("./Modules/TourismPartners"));
const NetworkMap = componentIfModule("NetworkMap", () => import("./Modules/NetworkMap"));
const NetworkTowns = componentIfModule("NetworkTowns", () => import("./Modules/NetworkTowns"));
const Around = componentIfModule("Around", () => import("./Modules/Around"));
const Lines = componentIfModule("Lines", () => import("./Modules/Lines"));
const RouteCalculation = componentIfModule("RouteCalculation", () => import("./Modules/RouteCalculation"));
const Thematic = componentIfModule("Thematic", () => import("./Modules/Thematic"));
const TextBoard = componentIfModule("TextBoard", () => import("./Modules/TextBoard"));
const Bike = componentIfModule("Bike", () => import("./Modules/Bike"));
const NetworkLines = componentIfModule("NetworkLines", () => import("./Modules/NetworkLines"));
const Projects = componentIfModule("Projects", () => import("./Modules/Projects"));
const MultiMobilities = componentIfModule("MultiMobilites", () => import("./Modules/MultiMobilities"));
const LinesRegions = componentIfModule("LinesRegions", () => import("./Modules/LinesRegions"));
const Analyzes = componentIfModule("Analyzes", () => import("./Modules/Analyzes"));
const Territories = componentIfModule("Territories", () => import("./Modules/Territories"));

class Board extends Component {
  state = {};

  isScrolling = null;

  renderModule = () => {
    const {
      module,
      map,
      lines,
      linesModes,
      placesRef,
      stops,
      areas,
      places,
      towns,
      touchscreenSelected,
      hash,
      stations,
      servicesStations,
      domElement,
      options,
    } = this.props;

    if (!module) {
      console.warn("An error has occured while loading module ...");
      return;
    }

    const props = {
      lines,
      linesModes,
      placesRef,
      map,
      stops,
      areas,
      places,
      towns,
      stations,
      servicesStations,
      touchscreenSelected,
      hash,
      domElement,
      options,
    };

    if (document.querySelector(".lc-popup-clickthrough")) {
      document.querySelector(".lc-popup-clickthrough").remove();
    }

    const Fallback = () => <div className="lc-fallback">{translate("loading")}</div>;

    switch (module.id) {
      case "partners":
      case "tourism-partners":
        return (
          <Suspense fallback={<Fallback />}>
            <TourismPartners moduleData={module} {...props} />
          </Suspense>
        );
      case "network-map":
        return (
          <Suspense fallback={<Fallback />}>
            <NetworkMap moduleData={module} {...props} />
          </Suspense>
        );
      case "network-towns":
        return (
          <Suspense fallback={<Fallback />}>
            <NetworkTowns moduleData={module} {...props} />
          </Suspense>
        );
      case "around":
        return (
          <Suspense fallback={<Fallback />}>
            <Around
              blockZoomAtStop={module.blockZoomAtStop}
              radius={module.radius}
              markerModeChanged={module.markerModeChanged}
              refreshDataInterval={module.refreshDataInterval}
              searchIn={module.searchIn}
              disableClickOnMap={module.disableClickOnMap}
              displayModuleInfo={module.displayModuleInfo}
              dontShowTimetable={module.dontShowTimetable}
              disableAutocompleteGeolocation={module.disableAutocompleteGeolocation}
              title={module.title}
              itemCoordsIfNotFound={module.itemCoordsIfNotFound}
              minDate={module.minDate}
              maxDate={module.maxDate}
              defaultDateTime={module.defaultDateTime}
              {...props}
            />
          </Suspense>
        );
      case "lines":
        return (
          <Suspense fallback={<Fallback />}>
            <Lines moduleData={module} {...props} />
          </Suspense>
        );
      case "route-calculation":
        return (
          <Suspense fallback={<Fallback />}>
            <RouteCalculation moduleData={module} {...props} />
          </Suspense>
        );
      case "thematic":
        return (
          <Suspense fallback={<Fallback />}>
            <Thematic moduleData={module} {...props} />
          </Suspense>
        );
      case "text-board":
        return (
          <Suspense fallback={<Fallback />}>
            <TextBoard moduleData={module} {...props} />
          </Suspense>
        );
      case "bike":
        return (
          <Suspense fallback={<Fallback />}>
            <Bike moduleData={module} {...props} />
          </Suspense>
        );
      case "network-lines":
        return (
          <Suspense fallback={<Fallback />}>
            <NetworkLines moduleData={module} {...props} />
          </Suspense>
        );
      case "projects":
        return (
          <Suspense fallback={<Fallback />}>
            <Projects moduleData={module} {...props} />
          </Suspense>
        );
      case "multimobilities":
        return (
          <Suspense fallback={<Fallback />}>
            <MultiMobilities moduleData={module} {...props} />
          </Suspense>
        );
      case "lines-regions":
        return (
          <Suspense fallback={<Fallback />}>
            <LinesRegions moduleData={module} {...props} />
          </Suspense>
        );
      case "analyzes":
        return (
          <Suspense fallback={<Fallback />}>
            <Analyzes moduleData={module} {...props} />
          </Suspense>
        );
      case "territories":
        return (
          <Suspense fallback={<Fallback />}>
            <Territories moduleData={module} {...props} />
          </Suspense>
        );
      default:
        break;
    }
  };

  resizeBoardContent = () => {
    const { top, isMobile, isMobileBoot } = this.props;

    if (isMobile || isMobileBoot) {
      window.scrollTo(0, top);

      window.addEventListener("scroll", () => {
        // Clear our timeout throughout the scroll
        clearTimeout(this.isScrolling);

        // Set a timeout to run after scrolling ends
        this.isScrolling = setTimeout(() => {
          // Run the callback
          appStore.dispatch(actionScrollEnd(window.pageYOffset));
        }, 50);
      });
    }
  };

  showBackButton = (modulesToRender) => {
    let { lock, options } = this.props;
    const { pathname } = history.location;
    const params = getURLSearchParams(history.location);

    // TODO finish list params useable
    const getParamsLength = Object.keys(params).filter(
      (param) =>
        [
          "lock",
          "tab",
          "size",
          "dont_show",
          "token",
          "client_id",
          "board",
          "hasAuthorizedCookies",
          "touchscreen",
          "lang",
        ].indexOf(param) === -1
    ).length;

    const hasParams = getParamsLength > 0;

    // Handle "change-line" feature on options, but only if we don't have "date" params which require a back anyways
    if (
      options?.features?.["change-line"] === false &&
      !params.date &&
      (pathname.includes("/lines") || pathname.includes("/route-calculation"))
    ) {
      return false;
    }

    const nbModulesToRender = Object.keys(modulesToRender)
      .map((renderType) => modulesToRender[renderType].length)
      .reduce((a, b) => a + b);

    if (nbModulesToRender <= 1) {
      lock = true;
    }

    if (lock) {
      if (pathname.includes("/lines")) {
        if (hasParams) {
          return true;
        } else {
          return false;
        }
      } else if (isActiveModule("around")) {
        if (hasParams) {
          return true;
        } else {
          return false;
        }
      } else if (pathname.includes("/route-calculation")) {
        if ((params.from && !params.to) || (!params.from && params.to)) {
          if (getParamsLength > 1) {
            return true;
          } else {
            return false;
          }
        } else if (params.from && params.to) {
          if (getParamsLength > 2) {
            return true;
          } else {
            return false;
          }
        }
      } else if (pathname.includes("/towns")) {
        if (hasParams) {
          return true;
        } else {
          return false;
        }
      } else if (pathname.includes("/hiking-routes")) {
        if (hasParams) {
          return true;
        } else {
          return false;
        }
      } else if (pathname.includes("/mentions")) {
        if (hasParams) {
          return true;
        } else {
          return false;
        }
      } else if (pathname.includes("places-interest")) {
        if (hasParams) {
          return true;
        } else {
          return false;
        }
      } else if (pathname.includes("/network-map")) {
        if (hasParams) {
          return true;
        } else {
          return false;
        }
      } else if (pathname.includes("/network-towns")) {
        if (hasParams) {
          return true;
        } else {
          return false;
        }
      } else if (pathname.includes("/network-lines")) {
        if (params.stop_area && !params.line) {
          return false;
        } else if (hasParams) {
          return true;
        } else {
          return false;
        }
      } else if (pathname.includes("tourism-partners") || pathname.includes("partners")) {
        if (hasParams) {
          return true;
        } else {
          return false;
        }
      } else {
        if (hasParams) {
          return true;
        } else {
          return false;
        }
      }
    } else {
      return true;
    }
  };

  // TODO optimize return with various
  back = () => {
    const { isMobile, modules } = this.props;
    const { pathname, search } = history.location;
    const params = getURLSearchParams(history.location);

    delete params.lock;

    if (envVarToBool(REACT_APP_EXPANDED_MAP) && isMobile) {
      appStore.dispatch(actionSetExpandedMap(false));
    }

    const returnToMultimobilities = (current) => {
      const module = modules.find((m) => m.id === current),
        multimobilities = modules.find((m) => m.id === "multimobilities");

      let usedInMultimobilities = null;

      if (multimobilities) {
        multimobilities.mobilities.forEach((mob, index) => {
          if (mob.data.find((d) => d.type === current)) {
            usedInMultimobilities = index;
          }
        });
      }

      if (module?.hide && usedInMultimobilities) {
        message({
          clicked: "back",
          from: pathname,
          from_params: params,
          to: `/multimobilities?mob=${usedInMultimobilities}`,
          to_params: {},
        });
        history.push(`/multimobilities?mob=${usedInMultimobilities}`);
      } else {
        message({
          clicked: "back",
          from: pathname,
          from_params: params,
          to: "/",
          to_params: {},
        });
        history.push("/");
      }
    };

    if (pathname.includes("lines-regions")) {
      if (params.cat && params.line) {
        const searchParam = "?cat=" + params.cat;

        message({
          clicked: "back",
          from: pathname,
          from_params: params,
          to: pathname,
          to_params: getURLSearchParams({ search: searchParam }),
        });
        history.push({
          pathname,
          search: searchParam,
        });
      } else if ((params.cat && !params.line) || (!params.cat && params.line && pathname.includes("/dl"))) {
        message({
          clicked: "back",
          from: pathname,
          from_params: params,
          to: pathname,
          to_params: {},
        });
        history.push(pathname);
      } else {
        message({
          clicked: "back",
          from: pathname,
          from_params: params,
          to: "/",
          to_params: {},
        });
        history.push("/");
      }
    } else if (pathname.includes("/lines")) {
      // Remove any current line information message
      appStore.dispatch(actionSetLineInformation(null));

      if (params.current) {
        if (params.date) {
          const searchParam = search.split("&date=")[0];

          message({
            clicked: "back",
            from: pathname,
            from_params: params,
            to: pathname,
            to_params: getURLSearchParams({ search: searchParam }),
          });
          history.push({ pathname, search: searchParam });
        } else {
          const searchParam = "?" + (params.stop_area ? `stop_area=${params.stop_area}&` : "");

          message({
            clicked: "back",
            from: pathname,
            from_params: params,
            to: pathname,
            to_params: getURLSearchParams({ search: searchParam }),
          });
          history.push({
            pathname,
            search: "?" + (params.stop_area ? `stop_area=${params.stop_area}&` : ""),
          });
        }
      } else if (params.stop_area) {
        message({ clicked: "back", from: pathname, from_params: params, to: pathname, to_params: {} });
        history.push({ pathname });
      } else {
        message({
          clicked: "back",
          from: pathname,
          from_params: params,
          to: "/",
          to_params: {},
        });
        history.push("/");

        // Dispatch the selected line action
        appStore.dispatch(actionOnLineSelected(null));
      }
    } else if (isActiveModule("around")) {
      if (params.line) {
        if (params.from) {
          if (params.date) {
            const searchParam = search.split("&date=")[0];

            message({
              clicked: "back",
              from: pathname,
              from_params: params,
              to: pathname,
              to_params: getURLSearchParams({ search: searchParam }),
            });
            history.push({ pathname, search: searchParam });
          } else {
            const searchParam = "?from=" + params.from;

            message({
              clicked: "back",
              from: pathname,
              from_params: params,
              to: pathname,
              to_params: getURLSearchParams({ search: searchParam }),
            });
            history.push({ pathname, search: searchParam });
          }
        } else {
          const searchParam = params.date ? search.split("&date=")[0] : "";

          message({
            clicked: "back",
            from: pathname,
            from_params: params,
            to: pathname,
            to_params: getURLSearchParams({ search: searchParam }),
          });
          history.push({
            pathname,
            search: searchParam,
          });
        }
      } else if (params.from && !params.line) {
        message({ clicked: "back", from: pathname, from_params: params, to: pathname, to_params: {} });
        history.push(pathname);
      } else {
        message({
          clicked: "back",
          from: pathname,
          from_params: params,
          to: "/",
          to_params: {},
        });
        history.push("/");

        // Dispatch the selected line action
        appStore.dispatch(actionOnLineSelected(null));
      }
    } else if (pathname.includes("/route-calculation")) {
      if (params.to && params.from) {
        if (params.journey) {
          const searchParam = `?from=${params.from}&to=${params.to}&date=${params.date}&modes=${params.modes}${
            params.extend ? "&extend=" + params.extend : ""
          }${params.walking ? "&walking=" + params.walking : ""}${params.cycling ? "&cycling=" + params.cycling : ""}${
            params.bikeProfile ? "&bikeProfile=" + params.bikeProfile : ""
          }`;

          message({
            clicked: "back",
            from: pathname,
            from_params: params,
            to: pathname,
            to_params: getURLSearchParams({ search: searchParam }),
          });
          history.push({
            pathname,
            search: searchParam,
          });
        } else if (params.date) {
          const searchParam = "?from=" + params.from + "&to=" + params.to;

          message({
            clicked: "back",
            from: pathname,
            from_params: params,
            to: pathname,
            to_params: getURLSearchParams({ search: searchParam }),
          });
          history.push({
            pathname,
            search: searchParam,
          });
        } else {
          returnToMultimobilities("route-calculation");
        }
      } else {
        returnToMultimobilities("route-calculation");
      }
    } else if (pathname.includes("/towns")) {
      if (params.insee && params.line) {
        if (params.date) {
          const searchParam = search.split("&date=")[0];

          message({
            clicked: "back",
            from: pathname,
            from_params: params,
            to: pathname,
            to_params: getURLSearchParams({ search: searchParam }),
          });
          history.push({ pathname, search: searchParam });
        } else {
          const searchParam = "?insee=" + params.insee;

          message({
            clicked: "back",
            from: pathname,
            from_params: params,
            to: pathname,
            to_params: getURLSearchParams({ search: searchParam }),
          });
          history.push({ pathname, search: searchParam });
        }
      } else if (params.insee && !params.line) {
        message({ clicked: "back", from: pathname, from_params: params, to: pathname, to_params: {} });
        history.push(pathname);
      } else {
        message({
          clicked: "back",
          from: pathname,
          from_params: params,
          to: "/",
          to_params: {},
        });
        history.push("/");

        // Dispatch the selected line action
        appStore.dispatch(actionOnLineSelected(null));
      }
    } else if (pathname.includes("/hiking-routes")) {
      let searchArgs = search.split("&");

      if (searchArgs.length > 1) {
        const toRemove = searchArgs[searchArgs.length - 1];
        const searchParam = search.replace("&" + toRemove, "");

        message({
          clicked: "back",
          from: pathname,
          from_params: params,
          to: pathname,
          to_params: getURLSearchParams({ search: searchParam }),
        });
        history.push({ pathname, search: searchParam });
      } else {
        if (search) {
          message({ clicked: "back", from: pathname, from_params: params, to: pathname, to_params: {} });
          history.push(pathname);
        } else {
          message({
            clicked: "back",
            from: pathname,
            from_params: params,
            to: "/",
            to_params: {},
          });
          history.push("/");
        }
      }
    } else if (pathname.includes("/mentions")) {
      message({
        clicked: "back",
        from: pathname,
        from_params: params,
        to: "/",
        to_params: {},
      });
      history.push("/");
      this.setState({ mentions: false });
    } else if (pathname.includes("places-interest")) {
      let searchArgs = search.split("&");

      // TODO FIX FOR PLACES-INTEREST TO THINK BETTER
      if (searchArgs.length > 1) {
        let toRemove = searchArgs[searchArgs.length - 1];
        const searchParam = search.replace("&" + toRemove, "");

        message({
          clicked: "back",
          from: pathname,
          from_params: params,
          to: pathname,
          to_params: getURLSearchParams({ search: searchParam }),
        });
        history.push({ pathname, search: searchParam });
      } else {
        if (search) {
          message({ clicked: "back", from: pathname, from_params: params, to: pathname, to_params: {} });
          history.push(pathname);
        } else {
          message({
            clicked: "back",
            from: pathname,
            from_params: params,
            to: "/",
            to_params: {},
          });
          history.push("/");

          // Dispatch the selected line action
          appStore.dispatch(actionOnLineSelected(null));
        }
      }
    } else if (pathname.includes("/network-map")) {
      if (params.line && params.from) {
        const searchParam = `?line=${params.from}`;

        message({
          clicked: "back",
          from: pathname,
          from_params: params,
          to: pathname,
          to_params: getURLSearchParams({ search: searchParam }),
        });
        history.push({
          pathname,
          search: searchParam,
        });
      } else if (params.line) {
        message({ clicked: "back", from: pathname, from_params: params, to: pathname, to_params: {} });
        history.push({
          pathname,
        });
      } else {
        message({
          clicked: "back",
          from: pathname,
          from_params: params,
          to: "/",
          to_params: {},
        });
        history.push("/");
      }
    } else if (pathname.includes("/network-towns")) {
      if (params.town && params.line && params.from) {
        const searchParam = `?town=${params.town}&line=${params.from}`;

        message({
          clicked: "back",
          from: pathname,
          from_params: params,
          to: pathname,
          to_params: getURLSearchParams({ search: searchParam }),
        });
        history.push({
          pathname,
          search: searchParam,
        });
      } else if (params.town && params.line) {
        const searchParam = `?town=${params.town}`;

        message({
          clicked: "back",
          from: pathname,
          from_params: params,
          to: pathname,
          to_params: getURLSearchParams({ search: searchParam }),
        });
        history.push({
          pathname,
          search: searchParam,
        });
      } else if (params.town) {
        message({ clicked: "back", from: pathname, from_params: params, to: pathname, to_params: {} });
        history.push({
          pathname,
        });
      } else {
        message({
          clicked: "back",
          from: pathname,
          from_params: params,
          to: "/",
          to_params: {},
        });
        history.push("/");
      }
    } else if (pathname.includes("/network-lines")) {
      if (params.stop_area && params.line) {
        const searchParam = `?stop_area=${params.stop_area}` + (params.tab ? "&tab=" + params.tab : "");

        message({
          clicked: "back",
          from: pathname,
          from_params: params,
          to: pathname,
          to_params: getURLSearchParams({ search: searchParam }),
        });
        history.push({
          pathname,
          search: searchParam,
        });
      } else if (params.town && params.line && params.from) {
        const searchParam = `?town=${params.town}&line=${params.from}` + (params.tab ? "&tab=" + params.tab : "");

        message({
          clicked: "back",
          from: pathname,
          from_params: params,
          to: pathname,
          to_params: getURLSearchParams({ search: searchParam }),
        });
        history.push({
          pathname,
          search: searchParam,
        });
      } else if (params.town && params.line) {
        const searchParam = `?town=${params.town}` + (params.tab ? "&tab=" + params.tab : "");

        message({
          clicked: "back",
          from: pathname,
          from_params: params,
          to: pathname,
          to_params: getURLSearchParams({ search: searchParam }),
        });
        history.push({
          pathname,
          search: searchParam,
        });
      } else if (params.town) {
        message({ clicked: "back", from: pathname, from_params: params, to: pathname, to_params: {} });
        history.push({
          pathname: pathname,
          search: params.tab ? "?tab=" + params.tab : "",
        });
      } else if (params.line && params.from) {
        const searchParam = `?line=${params.from}` + (params.tab ? "&tab=" + params.tab : "");

        message({
          clicked: "back",
          from: pathname,
          from_params: params,
          to: pathname,
          to_params: getURLSearchParams({ search: searchParam }),
        });
        history.push({
          pathname,
          search: searchParam,
        });
      } else if (params.line) {
        message({ clicked: "back", from: pathname, from_params: params, to: pathname, to_params: {} });
        history.push({
          pathname,
          search: params.tab ? "?tab=" + params.tab : "",
        });
      } else {
        message({
          clicked: "back",
          from: pathname,
          from_params: params,
          to: "/",
          to_params: {},
        });
        history.push("/");
      }
    } else if (pathname.includes("tourism-partners") || pathname.includes("partners")) {
      if (params.partner) {
        if (params.type) {
          const searchParam = `?type=${params.type}`;

          message({
            clicked: "back",
            from: pathname,
            from_params: params,
            to: pathname,
            to_params: getURLSearchParams({ search: searchParam }),
          });
          history.push({
            pathname,
            search: searchParam,
          });
        } else {
          message({ clicked: "back", from: pathname, from_params: params, to: pathname, to_params: {} });
          history.push(pathname);
        }
      } else if (params.type) {
        message({ clicked: "back", from: pathname, from_params: params, to: pathname, to_params: {} });
        history.push(pathname);
      } else {
        message({
          clicked: "back",
          from: pathname,
          from_params: params,
          to: "/",
          to_params: {},
        });
        history.push("/");
      }
    } else if (pathname.includes("analyzes")) {
      if (params.indicator && !params.line) {
        message({
          clicked: "back",
          from: pathname,
          from_params: params,
          to: "/analyzes",
          to_params: {},
        });
        history.push("/analyzes");
      } else if (params.indicator && params.line) {
        const searchParam = `?indicator=${params.indicator}`;

        message({
          clicked: "back",
          from: pathname,
          from_params: params,
          to: pathname,
          to_params: getURLSearchParams({ search: searchParam }),
        });
        history.push({
          pathname,
          search: searchParam,
        });
      } else if (!params.indicator && params.line) {
        message({
          clicked: "back",
          from: pathname,
          from_params: params,
          to: pathname,
          to_params: {},
        });
        history.push({
          pathname,
        });
      } else {
        message({
          clicked: "back",
          from: pathname,
          from_params: params,
          to: "/",
          to_params: {},
        });
        history.push("/");
      }
    } else if (pathname.includes("multimobilities")) {
      if (params.current) {
        const searchParam = search.split("&current=")[0];

        appStore.dispatch(actionSetMobilityObject(null));

        message({
          clicked: "back",
          from: pathname,
          from_params: params,
          to: pathname,
          to_params: getURLSearchParams({ search: searchParam }),
        });
        history.push({ pathname, search: searchParam });
      } else if (params.mob) {
        history.push({ pathname });
      } else {
        message({
          clicked: "back",
          from: pathname,
          from_params: params,
          to: "/",
          to_params: {},
        });
        history.push("/");
      }
    } else if (pathname.includes("territories")) {
      if (params.id) {
        message({
          clicked: "back",
          from: pathname,
          from_params: params,
          to: pathname,
          to_params: {},
        });
        history.push("/territories");
      } else {
        message({
          clicked: "back",
          from: pathname,
          from_params: params,
          to: "/",
          to_params: {},
        });
        history.push("/");
      }
    } else {
      if (params.place && params.line) {
        const searchParam = "?place=" + params.place;

        message({
          clicked: "back",
          from: pathname,
          from_params: params,
          to: pathname,
          to_params: getURLSearchParams({ search: searchParam }),
        });
        history.push({
          pathname,
          search: searchParam,
        });
      } else if (params.place) {
        message({ clicked: "back", from: pathname, from_params: params, to: pathname, to_params: {} });
        history.push({
          pathname,
        });
      } else if (params.line) {
        if (params.tiles) {
          message({ clicked: "back", from: pathname, from_params: params, to: pathname, to_params: {} });
          history.push({
            pathname,
            search: "?tiles=" + params.tiles,
          });
        } else if (params.line) {
          message({ clicked: "back", from: pathname, from_params: params, to: pathname, to_params: {} });
          history.push(pathname);
        }
      } else {
        message({
          clicked: "back",
          from: pathname,
          from_params: params,
          to: "/",
          to_params: {},
        });
        history.push("/");

        if (REACT_APP_TYPE === "projects") {
          appStore.dispatch(actionSetProjectsMenuID(1));
        }
      }

      // Dispatch the selected line action
      appStore.dispatch(actionOnLineSelected(null));
    }
  };

  getPage = (page) => {
    return (
      <>
        <div className="lc-board-header">
          <div
            className="back"
            onClick={() => this.back()}
            onKeyPress={(e) => handleKeyPress(e, () => this.back())}
            role="button"
            tabIndex="0"
            title={translate("title-back")}
          >
            <img src={assetsPath("/assets/images/back.svg")} alt={translate("back")} />
          </div>
          <div className="board-title">{page === "mentions" ? "mentions légales" : page}</div>
        </div>
        <div className="content page" dangerouslySetInnerHTML={{ __html: this.state.content }} />
      </>
    );
  };

  onLineSelected = async (line, marker) => {
    // If we have no routes on our line, just don't do anything
    if (line?.routes?.length === 0) {
      return;
    }

    this.props.history.push(`/lines?current=${line.id}_${line.direction_id}&stop=${marker.id}`);
  };

  onClickInfobox(marker, url) {
    const { history, map } = this.props;
    const path = `/${url}?from=${substringCoords(marker.coord)}`;

    history.push(path);

    map.setState({ infoboxs: [] });
  }

  onClickItemPage = (page) => {
    this.props.history.push("/" + page);
    this.setState({ [page]: true });
  };

  initMap = () => {
    const {
      lines,
      map,
      history,
      places = [],
      stations: dataStations = [],
      placesRef,
      isMobile,
      hash,
      heavyIds,
      complementaryIds,
      customMapLines,
      customMapMarkers,
      allLinesGeojson,
    } = this.props;

    if (map) {
      if (heavyIds.length || heavyIds === true) {
        axios
          .get(`/api/file?name=entrance_map&folder=map&ext=geojson`)
          .then((response) => buildEntranceMap(response.data, map));
      }

      if (REACT_APP_TERRITORY_OUTLINE && JSON.parse(REACT_APP_ZOOM).where === "territory_outline") {
        if (!this.props?.module?.zoomOnIt) {
          zoomOnTerritoryOutline(map);
        }
      } else if (JSON.parse(REACT_APP_ZOOM).where === "all_lines") {
        if (!allLinesGeojson || allLinesGeojson.length > 0) {
          axios.get(`/api/file?name=all-lines&ext=geojson`).then((responseAllLines) => {
            axios
              .get(`/api/file?name=lines-intersect&ext=geojson`)
              .then((response) => {
                if (response.data) {
                  appStore.dispatch(actionSetAllLinesintersecGeojson(response.data));
                }
              })
              .catch((e) => {
                console.log(e);
              })
              .finally(() => {
                appStore.dispatch(actionSetAllLinesGeojson(responseAllLines.data));
                setTimeout(() => buildAllLines(map, false));
              });
          });
        } else {
          appStore.dispatch(actionSetAllLinesSelected({ type: "", id: null }));
        }
      } else if (JSON.parse(REACT_APP_ZOOM).where === "start_point") {
        const mapElement = map?.mapReference?.current?.leafletElement;

        mapElement &&
          mapElement.setView(
            JSON.parse(REACT_APP_START_POINT)[isMobile ? "mobile" : "desktop"],
            JSON.parse(REACT_APP_ZOOM)[isMobile ? "mobile" : "desktop"]
          );
      }

      if (REACT_APP_STREETVIEW) {
        const target = document.getElementById("lcmap");

        target.ondragover = (e) => {
          e.preventDefault();
          e.dataTransfer.dropEffect = "move";
        };

        target.ondrop = (e) => {
          e.preventDefault();
          const coords = map.mapReference.current.leafletElement.containerPointToLatLng(
            L.point([e.clientX, REACT_APP_HEADER ? e.clientY - (isMobile ? 60 : 120) : e.clientY])
          );

          appStore.dispatch(actionSetStreetviewPosition(coords));
          const streetviewMarker = buildStreeviewMarker();

          appStore.dispatch(actionSetCustomMarkers([streetviewMarker]));
        };
      }
    }

    // Retrieve places to display them in the map
    const data = places.concat(dataStations);
    const placesToDisplay = placesRef ? placesRef.find((p) => p.name === "map-background") : [];

    const mapPlaces = data.filter((place) => {
      return placesToDisplay && placesToDisplay.places ? placesToDisplay.places.includes(place.cat_id) : [];
    });

    // Display all places cat defined in map-background
    appStore.dispatch(actionBuildMapPlaces(mapPlaces));

    if (map && REACT_APP_TYPE !== "network") {
      if (complementaryIds?.length > 0) {
        appStore.dispatch(actionBuildComplementaryLines());
      }

      // Build, display heavy lines
      if (heavyIds?.length > 0) {
        appStore.dispatch(actionBuildHeavyLines(JSON.parse(REACT_APP_ZOOM).where));
      }

      if (customMapLines?.length > 0) {
        appStore.dispatch(actionBuildCustomLines(customMapLines));
      }

      if (customMapMarkers?.length > 0) {
        appStore.dispatch(actionSetCustomMarkers(buildCustomMarkers(customMapMarkers)));
      }

      // Reset markers on board load
      appStore.dispatch(actionSetReduxMarkers([]));

      updateMapEvents(map, "onMoveEnd", async (e) => {
        // TODO Do not display markers on Route Calculation
        if (
          history.location.pathname.includes("route-calculation") ||
          history.location.pathname.includes("bike") ||
          (history.location.pathname.includes("lines") && history.location.search.includes("current=")) ||
          (history.location.pathname.includes("multimobilities") && history.location.search.includes("current="))
        ) {
          return;
        }

        const { reactAreas, reactStops, terminusStyle } = this.props;
        const url = updateURLState(history.location);
        let lineId = false;

        if (url.line) {
          lineId = url.line.includes("_f")
            ? url.line.substring(0, url.line.lastIndexOf("_f"))
            : url.line.includes("_b")
            ? url.line.substring(0, url.line.lastIndexOf("_b"))
            : url.line;
        }

        const zoom = map.mapReference.current.leafletElement.getZoom();
        const bounds = e.sourceTarget.getBounds();
        const markers = [];

        if (reactAreas) {
          for (const area of reactAreas) {
            // Retrieve the marker only if it's inside the map bounds
            if (bounds.contains(area.props.position)) {
              // Below REACT_APP_AREAS_ZOOM_LEVEL, display only heavy lines or cluster if there is no heavy lines
              if (
                zoom >= 14 &&
                zoom < +REACT_APP_AREAS_ZOOM_LEVEL &&
                REACT_APP_HEAVY_LINES &&
                REACT_APP_HEAVY_LINES.length
              ) {
                for (const line of area.props.area.lines) {
                  if ((heavyIds.includes(line.id) || (lineId && line.id === lineId)) && markers.indexOf(area) < 0) {
                    markers.push(area);
                  }
                }
              } else if (zoom >= 17) {
                // 17 or above, display stops
                // Retrieve all physical stops for this stop area
                markers.push(
                  ...reactStops.filter((stop) => {
                    return stop.props.stop.stop_area === area.props.area.id;
                  })
                );
              } else if (zoom >= +REACT_APP_AREAS_ZOOM_LEVEL && zoom < 17) {
                // Display areas between REACT_APP_AREAS_ZOOM_LEVEL & 17 §17 is stops points, no matter what)
                markers.push(area);
              }
            }
          }

          if (
            REACT_APP_POLES &&
            ["around", "lines"].some(
              (condition) => isActiveModule(condition) || history.location.pathname.includes(condition)
            )
          ) {
            addPolesExchanges(reactAreas, markers, zoom);
          }
        }

        if (lineId) {
          const line = lines.find((l) => l.id === lineId);
          const direction = url.line.includes("_b") ? "b" : "f";

          if (!line.stops) {
            if (this.props.module?.id !== "thematic" && this.props.module?.type !== "searchOnly") {
              try {
                const response = await axios.get(
                  `/api/file?folder=stops&name=${encodeURIComponent(line.code)}_${line.network}_${direction}~${hash}`
                );

                line.stops = response.data;
              } catch (e) {
                const error = e.response && e.response.data ? e.response.data.id : e;

                console.warn(error);
              }
            }
          }

          const terminusLine = line.stops.filter((s) => s.terminus);

          for (const terminus of terminusLine) {
            // ? Todo check if there is no regression on this
            if (!terminus.lines) {
              continue;
            }

            markers.push(
              appStore.dispatch(
                actionBuildMarker(terminus, {
                  key: line.code + "_" + terminus.index,
                  icon: new L.DivIcon({
                    className: "lc-circle-icon-marker lc-circle-icon-marker-terminus",
                    iconSize: [10, 10],
                    tooltipAnchor: new L.Point(5, 0),
                    html: `<span style="border: 3px solid #${line.color}" />`,
                  }),
                  stop: terminus,
                  zIndexOffset: 200,
                  terminus: true,
                })
              )
            );
          }

          appStore.dispatch(actionSetReduxMarkers(markers));
          setTimeout(() => {
            // TODO find a better way :-)
            document.querySelectorAll(".lc-tooltip-leaflet-terminus").forEach((div) => {
              div.style.borderColor = "#" + line.color;

              if (!terminusStyle) {
                div.style.backgroundColor = "#" + line.color;
                div.style.color = luminance(line.color) > 0.5 ? "#333" : "#fff";
                div.style.padding = "2px 8px";
              } else if (["imageAndCityName"].includes(terminusStyle)) {
                div.style.padding = "2px 8px 2px 2px";
              }
            });
          });
        } else {
          // Dispatch the new created markers
          appStore.dispatch(
            actionSetReduxMarkers(
              markers.filter((m) => {
                const type = Object.keys(m.props).includes("area") ? "area" : "stop";

                return m.props[type].lines.length;
              })
            )
          );
        }
      });

      this.removeEventListener = addResizeEvent(isMobile);
    }
  };

  componentDidUpdate(prevProps) {
    !prevProps.isMobile && this.props.isMobile && this.resizeBoardContent();

    if (this.props.modules.length === 1 && REACT_APP_INIT_MAP_ON_BACK && !this.module && prevProps.module) {
      const { pathname, search } = history.location;

      if (pathname === `/${prevProps.module.id}` && !search) {
        this.initMap();
      }
    } else if (!this.module && !prevProps.module && REACT_APP_INIT_MAP_ON_BACK) {
      this.initMap();
    }
  }

  async componentDidMount() {
    const { lines, history, modules, touchscreenSelected } = this.props;

    if (history.location.pathname === "/") {
      appStore.dispatch(actionSetComponent(this));
      UpdatePageView();
    }

    // Send a component loaded message
    message({ loaded: "board" });

    // Remove previous geolocation errors
    appStore.dispatch(actionSetGeolocationError(null));

    // TODO USE TEXT-BOARD MODULE FOR MENTIONS LEGALES/...
    let content = null;

    const pageModule = modules.find(
      (m) => !m.hide && (touchscreenSelected ? m.touchscreen : m) && m && m.id === "page"
    );

    pageModule &&
      (await axios
        .get("/api/file?folder=pages&ext=html&name=" + pageModule.file)
        .then((response) => {
          content = response.data;
        })
        .catch((e) => {
          const error = e.response && e.response.data ? e.response.data.id : e;

          console.warn(error);
        }));
    this.setState({ content });

    if (history.location.pathname === "/mentions") {
      this.setState({ mentions: true });
    }

    // Dispatch action to build entrance map
    this.initMap();

    // No lines, no mount
    if (!lines.length) {
      return;
    }

    // Fix iOS touchmove problem (map and board moving without touching theme
    //document.addEventListener('touchmove', () => { })
  }

  componentWillUnmount() {
    const { map } = this.props;

    if (map && REACT_APP_TYPE !== "network") {
      map.setState({
        polylines: [],
        markers: [],
        markersPlaces: [],
        clusters: null,
        status: null,
        infoboxs: [],
        selectedInfobox: null,
        pin: null,
        circle: null,
        terminus: false,
        infoboxsTerminus: [],
      });
      removeMapEvents(map);
      this.removeEventListener && this.removeEventListener();

      if (map.mapReference.current && this.onStreetViewChanged) {
        this.onStreetViewChanged.remove();
      }
    }
  }

  handleProjectsLinkClick = (event, module) => {
    const target = event.target;
    const projectsLinkSelected = document.querySelector(".lc-projects-link-selected");

    if (!module) {
      appStore.dispatch(actionSetProjectsMenuID(1));
    }

    // If we click on the same link again, just back to / & remove the class
    if (target.classList.contains("lc-projects-link-selected")) {
      target.classList.remove("lc-projects-link-selected");

      history.push({
        pathname: "/",
      });
      appStore.dispatch(actionSetProjectsMenuID(1));

      event.preventDefault();
      return false;
    }

    if (projectsLinkSelected) {
      projectsLinkSelected.classList.remove("lc-projects-link-selected");
    }

    if (module) {
      appStore.dispatch(actionSetProjectsMenuID(module.position));
    }

    if (target.classList.contains("lc-menu-title")) {
      target.classList.add("lc-projects-link-selected");
    } else if (target.classList.contains("lc-menu-item")) {
      target.querySelector(".lc-menu-title").classList.add("lc-projects-link-selected");
    }
  };

  render() {
    const {
      modules,
      module,
      size,
      language,
      isMobile,
      touchscreenSelected,
      selectedTown,
      selectedPartner,
      selectedPartnerType,
      options,
      showBoard,
      isExpandedMap,
      showModalGeneric,
    } = this.props;

    const params = getURLSearchParams(history.location);
    const regex = /(http[s]?:\/\/[^/\s]+)/g;
    const matches = document.referrer.match(regex);
    const origin = matches && document.referrer.match(regex).shift();

    const modulesToRender = modules
      .filter((m) => {
        if (options && options.menu && options.menu.links) {
          if (m.id === "thematic") {
            return (
              Object.keys(options.menu.links)
                .filter((link) => !options.menu.links[link])
                .indexOf(m.data) === -1
            );
          } else {
            return (
              Object.keys(options.menu.links)
                .filter((link) => !options.menu.links[link])
                .indexOf(m.id) === -1
            );
          }
        } else {
          return !m.hide && (touchscreenSelected ? m.touchscreen : m) && m;
        }
      })
      .sort((a, b) => +(a.position > b.position) || +(a.position === b.position) - 1)
      .reduce(
        (accumulator, currentModule) => {
          const type =
            currentModule.id === "pdf-download"
              ? "pdf"
              : currentModule.id === "page"
              ? "page"
              : currentModule.submodule
              ? "subs"
              : "mains";

          if (currentModule.id === "pdf-download") {
            switch (currentModule.type) {
              case "local":
                currentModule.url = assetsPath("/assets") + currentModule.link[language];
                break;
              case "extern":
                currentModule.url = origin + currentModule.link[language];
                break;
              case "link":
                currentModule.url = currentModule.link[language];
                break;
              default:
                break;
            }
          }

          accumulator[type].push(currentModule);

          return { ...accumulator };
        },
        {
          mains: [],
          subs: [],
          page: [],
          pdf: [],
        }
      );

    let blockBackMenuUnique = false;
    let showBackButton = this.showBackButton(modulesToRender);

    // Si on a qu'un module à render, on enlève le back button
    if (Object.keys(modules).length === 1 && module) {
      for (const p of ["lock", "size", "touchscreen", "stop"]) {
        delete params[p];
      }

      if (Object.keys(params).length === 0) {
        blockBackMenuUnique = true;
      }
    }

    const boardHead = modules.find((module) => module.id === "board-head");

    return (
      <>
        {showModalGeneric && <ModalGeneric />}

        <section
          className={
            "lc-board" +
            (!module ? " lc-no-module" : "") +
            ` lc-${size || options?.config?.size}` +
            (!showBoard ? " lc-no-board" : "")
          }
          data-lc-board
        >
          {module && (
            <div className="lc-content">
              {boardHead && (
                <div key={`${boardHead.id}`} className="lc-board-head">
                  <Link to="/" onClick={this.handleProjectsLinkClick}>
                    <img src={boardHead.image} alt={boardHead.title} />
                  </Link>
                </div>
              )}
              <div
                className={
                  "lc-board-header " +
                  (envVarToBool(REACT_APP_HIDE_BOARD_NO_BACK_BUTTON) && !showBackButton ? "lc-no-board-header" : "")
                }
              >
                {showBackButton && !blockBackMenuUnique && (
                  <button className="lc-back" tabIndex="0" onClick={() => this.back()}>
                    <img src={assetsPath("/assets/images/back.svg")} alt={translate("back")} />
                  </button>
                )}
                {envVarToBool(REACT_APP_EXPANDED_MAP) && isMobile && (
                  <button
                    className="lc-expand-map"
                    onClick={() => appStore.dispatch(actionSetExpandedMap(!isExpandedMap))}
                    onKeyPress={(e) => handleKeyPress(e, () => appStore.dispatch(actionSetExpandedMap(!isExpandedMap)))}
                  >
                    {isExpandedMap ? (
                      <img
                        src={assetsPath("/assets/images/chevron-up.svg")}
                        alt={translate("board-close-expanded-map")}
                      />
                    ) : (
                      <img
                        src={assetsPath("/assets/images/chevron-down.svg")}
                        alt={translate("board-open-expanded-map")}
                      />
                    )}
                  </button>
                )}
                <h1
                  className={
                    "lc-board-title" +
                    (!showBackButton ? " lc-board-title-lock" : "") +
                    (selectedTown || selectedPartnerType || selectedPartner ? " lc-with-subtitle" : "") +
                    (selectedPartner?.topLikes ? " lc-with-topLikes" : "")
                  }
                  tabIndex="-1"
                >
                  {selectedPartner ? (
                    <div
                      className={`lc-board-title-uppercase ${selectedPartner.name.length > 60 ? " lc-long-name" : ""}`}
                    >
                      <div className="lc-board-title-subtitle">{translate(module.title)}</div>
                      {selectedPartner.name}
                    </div>
                  ) : selectedPartnerType ? (
                    <div className="lc-board-title-uppercase">
                      <div className="lc-board-title-subtitle">{translate(module.title)}</div>
                      {selectedPartnerType}
                    </div>
                  ) : module.id === "network-lines" && showBackButton ? (
                    translate(module.tabs[params.tab ? params.tab : 0].title)
                  ) : (
                    translate(module.title)
                  )}
                  {selectedTown && (
                    <div className="lc-board-title-town" dangerouslySetInnerHTML={{ __html: selectedTown.name }} />
                  )}
                </h1>
              </div>
              {this.renderModule()}
            </div>
          )}
          {!module &&
            (this.state.mentions ? (
              this.getPage("mentions")
            ) : (
              <div className="lc-scrolling">
                {modules.length ? (
                  <>
                    <div className={"lc-content lc-main lc-home"}>
                      {envVarToBool(REACT_APP_EXPANDED_MAP) && isMobile && (
                        <button
                          className="lc-expand-map"
                          onClick={() => appStore.dispatch(actionSetExpandedMap(!isExpandedMap))}
                          onKeyPress={(e) =>
                            handleKeyPress(e, () => appStore.dispatch(actionSetExpandedMap(!isExpandedMap)))
                          }
                        >
                          {isExpandedMap ? (
                            <img
                              src={assetsPath("/assets/images/chevron-up.svg")}
                              alt={translate("board-close-expanded-map")}
                            />
                          ) : (
                            <img
                              src={assetsPath("/assets/images/chevron-down.svg")}
                              alt={translate("board-open-expanded-map")}
                            />
                          )}
                        </button>
                      )}
                      {Object.keys(modulesToRender)
                        .filter((m) => m !== "pdf")
                        .map((groupModule) => {
                          if (groupModule === "page" && modulesToRender[groupModule].length) {
                            return (
                              <div
                                key={groupModule}
                                onClick={() => this.onClickItemPage(modulesToRender[groupModule][0].file)}
                                onKeyPress={(e) =>
                                  handleKeyPress(e, () => this.onClickItemPage(modulesToRender[groupModule][0].file))
                                }
                                role="button"
                                tabIndex="0"
                                className="lc-menu-item-page"
                              >
                                {modulesToRender[groupModule][0].title}
                              </div>
                            );
                          } else {
                            return (
                              <div key={groupModule} className={groupModule === "subs" ? "lc-sub-module" : ""}>
                                {modulesToRender[groupModule].map((module, index) => {
                                  if (module.id === "title-board") {
                                    return (
                                      <UITitleBoard
                                        key={`${module.id}_${index}`}
                                        module={module}
                                        index={index}
                                        isMobile={this.props.isMobile}
                                      />
                                    );
                                  }

                                  if (module.id === "board-head") {
                                    return (
                                      <div key={`${module.id}_${index}`} className="lc-board-head">
                                        <Link to="/" onClick={this.handleProjectsLinkClick}>
                                          <img src={module.image} alt={module.title} />
                                        </Link>
                                      </div>
                                    );
                                  } else {
                                    if (module.id === "projects" && module.noLink) {
                                      return (
                                        <Link
                                          to={`?tiles=${module.tiles}`}
                                          key={`${module.id}_${index}`}
                                          className="lc-menu-item"
                                          onClick={(e) => this.handleProjectsLinkClick(e, module)}
                                        >
                                          <div className={!module.submodule ? "lc-menu-item-content" : ""}>
                                            <div
                                              className="lc-menu-title"
                                              dangerouslySetInnerHTML={{
                                                __html: translate(!module.submodule ? module.title : module.text),
                                              }}
                                            />
                                          </div>
                                        </Link>
                                      );
                                    }

                                    return (
                                      <Link
                                        key={`${module.id}_${index}`}
                                        className={`lc-menu-item${
                                          index === modulesToRender[groupModule].length - 1 &&
                                          !modules.find((m) => m.submodule)
                                            ? " lc-ending-menu"
                                            : ""
                                        }`}
                                        to={`/${
                                          ["thematic", "text-board", "projects", "lines-regions"].includes(module.id)
                                            ? module.data
                                            : module.url || module.id
                                        }${
                                          ["projects"].includes(module.id) && module.tiles
                                            ? `?tiles=${module.tiles}`
                                            : ""
                                        }`}
                                      >
                                        {module.image && (
                                          <img
                                            className="lc-images"
                                            src={assetsPath(module.image)}
                                            alt=""
                                            aria-hidden="true"
                                          />
                                        )}
                                        <div className={!module.submodule ? "lc-menu-item-content" : ""}>
                                          <div
                                            className="lc-menu-title"
                                            dangerouslySetInnerHTML={{
                                              __html: translate(!module.submodule ? module.title : module.text),
                                            }}
                                          />
                                          {!this.props.isMobile && !module.submodule && module.description && (
                                            <div
                                              className="lc-menu-item-description"
                                              dangerouslySetInnerHTML={{
                                                __html: translate(module.description),
                                              }}
                                            />
                                          )}
                                        </div>
                                        {!module.submodule && <div className="lc-menu-item-arrow" />}
                                      </Link>
                                    );
                                  }
                                })}
                              </div>
                            );
                          }
                        })}
                    </div>
                    {modulesToRender.pdf.map((pdfModule, index) => {
                      return (
                        <div key={`${pdfModule.id}_${index}`} className="lc-sub-content lc-main">
                          <a
                            className="lc-menu-item"
                            href={pdfModule.url}
                            target={pdfModule.blank === false ? "_self" : "_blank"}
                            rel="noopener noreferrer"
                            onClick={() =>
                              updateDataLayer({
                                event: "map-consultationPDFPlans",
                              })
                            }
                          >
                            <img
                              className="lc-images"
                              src={assetsPath("/assets/images/menu/pdf.svg")}
                              alt=""
                              aria-hidden="true"
                            />
                            <div className="lc-menu-item-content">
                              {pdfModule.title ? (
                                <div className="lc-menu-item-content">
                                  <div className="lc-menu-title">{translate(pdfModule.title)}</div>
                                  {!this.props.isMobile && (
                                    <div className="lc-menu-item-description">{translate(pdfModule.description)}</div>
                                  )}
                                </div>
                              ) : (
                                <div className="lc-menu-title">{translate(pdfModule.text)}</div>
                              )}
                            </div>
                            <div className="lc-menu-item-arrow" />
                          </a>
                        </div>
                      );
                    })}
                  </>
                ) : (
                  <div className="lc-loading" data-lc-loading>
                    <img src={assetsPath("/assets/images/loading.gif")} width={30} alt={translate("loading")} />
                  </div>
                )}
              </div>
            ))}
        </section>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    top: state.app.top,
    isMobile: state.app.isMobile,
    modules: state.app.modules,
    map: state.app.map,
    lines: state.app.lines,
    linesModes: state.app.linesModes,
    stops: state.app.stops,
    areas: state.app.areas,
    places: state.app.places,
    placesRef: state.app.placesRef,
    towns: state.app.towns,
    stations: state.app.stations,
    servicesStations: state.app.servicesStations,
    heavyIds: state.app.heavyIds,
    complementaryIds: state.app.complementaryIds,
    customMapLines: state.app.customMapLines,
    customMapMarkers: state.app.customMapMarkers,
    language: state.app.language,
    lock: state.app.lock,
    size: state.app.size,
    touchscreenSelected: state.app.touchscreenSelected,
    hash: state.app.hash,
    domElement: state.app.domElement,
    reactAreas: state.map.reactAreas,
    reactStops: state.map.reactStops,
    selectedTown: state.network.town,
    selectedPartner: state.tourismPartners.partner,
    selectedPartnerType: state.tourismPartners.typePartner,
    allLinesGeojson: state.map.allLinesGeojson,
    isExpandedMap: state.app.isExpandedMap,
    terminusStyle: state.app.terminusStyle,
    showModalGeneric: state.modalGeneric.showModalGeneric,
    streetviewPosition: state.app.streetviewPosition,
    dontShow: state.app.dontShow,
  };
};

export default withRouter(connect(mapStateToProps)(Board));
